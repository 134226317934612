<script setup>
import {onBeforeMount, onBeforeUnmount, ref} from 'vue';
import { useRouter } from 'vue-router';
import {useStore} from "vuex";

const router = useRouter(); // Получаем объект маршрута
const store = useStore();
const body = document.getElementsByTagName("body")[0];

const id = ref(null);

id.value = router.currentRoute.value.params.id; // Получаем id из объекта маршрута

onBeforeMount(() => {
  store.state.layout = "custom";
  store.state.layoutId = id.value;
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
  //body.classList.add("profile-overview");
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.layout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
  body.classList.remove("profile-overview");
});
const latitude = ref(null);
const longitude = ref(null);
const error = ref(null);

const getLocationCordova = async () => {
  document.addEventListener("deviceready", getLocation);
}

function getLocation() {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
        position => {
          latitude.value = position.coords.latitude;
          longitude.value = position.coords.longitude;
          console.log(longitude);
        },
        error => {
          error.value = error.message;
        }
    );
  } else {
    error.value = "Геолокация недоступна";
  }
}
</script>


<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="col-12 col-lg-12 mx-auto mt-9">
              <div class="card">
                <div class="card-body">
                  /offers/{{id}}<br>
                  страница описания заказа, получить пин, оставить отзыв id: <strong>{{id}}</strong>
                  <br><br>
                  <button @click="getLocationCordova">Получить геолокацию</button>
                  <p v-if="latitude && longitude">Широта: {{ latitude }}, Долгота: {{ longitude }}</p>
                  <p v-if="error">Ошибка: {{ error }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
